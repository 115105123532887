import React from "react";
import "./style.scss";

const Errorpage = () => {
  return (
    <>
      <div className="container-fluid page-not-found">
        <div className="row justify-content-center align-items-center page-content">
          <div className="col-10 col-md-6 col-lg-4 text-center">
            <h2>OOPS!</h2>
            <h3>Sorry, Page Not Found</h3>
            <a href="/">
              <button className="btn btn-primary go-home-btn">Go Home</button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Errorpage;
