import React from 'react'
import './style.scss';
import { Card, Row, Col, ProgressBar, Badge } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import project1 from "../../Assets/project1.png"
import project2 from "../../Assets/project2.png"
import project3 from "../../Assets/project3.png"

const OngoingProjectSection = () => {
    const projects = [
        {
          title: 'Bluestem Reserve',
          imageUrl: project1, // Replace with actual image URL
          progress: 90,
          remainingAmount: '$300k',
        },
        {
          title: 'Jefferson Phase 2',
          imageUrl: project2, // Replace with actual image URL
          progress: 50,
          remainingAmount: '$5.6m',
        },
        {
          title: 'Briarwood Phase 2',
          imageUrl: project3, // Replace with actual image URL
          progress: 75,
          remainingAmount: '$900K',
        },
      ];
  return (
    <section className='ongoing-project '>
        <div className="container my-5">
      <h2 className="mb-4">
        <span className="" style={{color: "#8ed61d"}}>|</span> Ongoing Projects
      </h2>
      <Row>
        {projects.map((project, index) => (
          <Col md={4} key={index}>
            <Card className="mb-4 shadow-sm">
              <Card.Img variant="top" src={project.imageUrl} />
              <Card.Body>
                <Badge pill bg="" className="position-absolute top-0 end-0 m-3" style={{ backgroundColor: "#8ed61d", color: "white" }}>
                  Ongoing
                </Badge>
                <Card.Title className="mt-2 mb-2" style={{fontWeight: "bold"}}>{project.title}</Card.Title>
                <ProgressBar
                  now={project.progress}
                  style={{ backgroundColor: "#e9f6e0",  }}
                  className='mt-2 mb-2'
                >
                  <div
                    style={{
                      width: `${project.progress}%`,
                      backgroundColor: "#8ed61d",
                      height: "100%",
                      position: "relative"
                    }}
                    
                  >
                        <div
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: `100%`,
                      transform: 'translateX(-50%) translateY(-50%)',
                      width: '20px',
                      height: '20px',
                      borderRadius: '50%',
                      backgroundColor: '#8ed61d',
                      border: '2px solid #fff',
                    }}
                  />
                  </div>
                  
                </ProgressBar>
                <div className="d-flex justify-content-between align-items-center">
                  <span style={{fontWeight: "500"}}>Remaining amount</span>
                  <span className=" fs-5 fw-bold" style={{color: "#8ed61d"}}>{project.remainingAmount}</span>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
    </section>
    
  )
}

export default OngoingProjectSection